<template>
  <div class="p-5 xl:px-0">
    <h1 class="mb-4 text-lg">Schools</h1>
    <transition name="fade" mode="out-in">
      <FlashMessage message="loading..." v-if="loading && !schools.length" key="loading" />

      <div class="flex flex-col" v-else>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Town</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Postcode</th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(school, schoolIdx) in schools" :key="school.id" :class="schoolIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <td class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                      {{ school.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                      {{ school.town }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                      {{ school.postcode }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap font-medium flex justify-end">
                      <modal title="School" class="cursor-pointer" component="School" :attributes="{ payload: school }" width="lg" buttonClass="mx-1"  v-permission="['schools.edit']">
                        <font-awesome-icon icon="pen" />
                      </modal>

                      <modal title="Are you sure?" class="cursor-pointer ml-6 " buttonClass="text-red-600" component="DeleteConfirm" :attributes="{ message: 'Are you sure you want to delete this school?', id: school.id, resource: 'schools' }"  v-permission="['schools.delete']">
                        <font-awesome-icon icon="trash" v-permission="['schools.delete']"/>
                      </modal>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <FlashMessage :message="message" v-if="message" />
      <FlashMessage :error="error" v-if="error" key="error" />
    </transition>
    <transition name="fade">
      <BasePagination path="schools" :meta="meta" :links="links" action="school/paginateSchools" v-if="meta && meta.last_page > 1" />
    </transition>

    <footer class="fixed left-0 bottom-0 w-full bg-gray-900" v-permission="['schools.edit']">
      <div class="flex items-center justify-between flex-wrap bg-teal-500 p-6 container mx-auto">
        <modal title="School" class="cursor-pointer" component="School" width="lg">
          <BaseBtn type="span" icon="plus" text="Add School" class="border-white" />
        </modal>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPen, faKey, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/BaseBtn";

library.add(faPen, faKey, faTrash);

export default {
  name: "SchoolsView",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn },
  computed: {
    ...mapGetters("school", ["loading", "error", "schools", "meta", "links"]),
  },
  data() {
    return {
      message: ''
    }
  },
  created() {
    this.$eventBus.$on("schools:updated", (val) => {
      this.getSchools();
      this.message = 'School updated'
    });
    this.$eventBus.$on("schools:added", (val) => {
      this.getSchools();
      this.message = 'School added'
    });
    this.$eventBus.$on("record:deleted", (val) => {
      this.getSchools();
      this.message = 'School deleted'
    });
  },
  methods: {
    getSchools() {
      const currentPage = 1;
      this.$store.dispatch("school/getSchools", currentPage);
    },
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("school/getSchools", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  },
};
</script>
